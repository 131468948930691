let translation =  {
    translation: {
        mobile: {
            landscape: 'Please switch your device into portrait mode.'
        },
        cookie: {
            ok: 'OK',
            message: 'STRAUSS utilise des cookies sur cette page. Vous pouvez trouver plus d\'informations sur notre <a target="_blank" href="https://www.strauss.com/de/fr/Aspects_juridiques/Cargokonfigurator_Datenschutz">page de protection</a> des données.'
        },
        splashscreen: {
            title: 'CREER VOTRE PROPRE DESIGN',
            subtitle: 'POCHETTES SUR MESURE',
            link: 'commencez dès maintenant',
            store: 'ONLINE-SHOP',
            storeUrl: 'https://www.strauss.com/de/fr'
        },
        startscreen: {
            title: 'CONCEVEZ VOS POCHES INDIVIDUELLES',
            subtitle: 'Commencez dès maintenant et créez des combinaisons de poches individuelles ou chargez un design existant à l\'aide d\'un code d\'identification.',
            firstpocket: 'Créer une nouvelle poche',
            code: {
                placeholder: 'ENTREZ VOTRE CODE',
                info: 'Charger la configuration existante et continuer'
            },
            defaultconfigname: 'Poche nouvelle'
        },
        notfound: {
            title: 'PAGE NON TROUVÉE',
            subtitle: "La page à laquelle vous tentez d'accéder n'est pas disponible. Vous avez peut-être mal tapé l'adresse ou la page a été déplacée."
        },
        pocketscreen: {
            title: 'VOTRE CONFIGURATION',
            pockets: {
                add: 'Créer une nouvelle poche',
                total: 'TOTAL',
                final: 'Votre demande',
                price: 'Prix'
            },
            incVat: 'TTC',
            excVat: 'HAT',
            share: 'Économisez et partagez vos poches',
            shared: 'Le code a été copié dans le presse-papier!',
            info: 'Veuillez noter que votre configuration ne comprend que les modules de poche. Les produits de base ne sont pas inclus dans la demande. Vous pouvez commander ces articles dans notre boutique en ligne. Cliquez <a href="https://www.strauss.com/de/fr/f/vetements-de-travail/Systemes_de_poches:e_s_tool_concept/Proprietes_d_entretien:Lavable_a_moins_de_60_C/" target="_blank">ici</a>.',
            overlap: 'Il y a un chevauchement dans l\'une de vos poches. Veuillez repositionner les modules sans chevauchement ou retirer la poche pour continuer.',
            shippingTime: 'Délai de livraison estimé : 5 semaines',
            attach: 'attacher',
            attachEmpty: 'Aucune configuration disponible'
        },
        editscreen: {
            title: 'VOTRE CONFIGURATION',
            name: {
                placeholder: 'NOM DE POCHE'
        },
            pocket: {
                name: 'SUPERFICIE',
                left: 'À GAUCHE',
                right: 'À DROITE'
            },
            modules: {
                emptyinfo: 'Ajoutez votre premier module de poche',
                nofitInfo: 'Malheureusement, si un module de poche n\'est plus disponible pour l\'ajouter à la zone de configuration, il n\'y a pas assez d\'espace',
                info: 'VOS MODULES DE POCHE',
                add: 'AJOUTER',
                total: 'TOTAL',
                save: 'SAUVER',
                cancel: 'ANNULER',
                capacity: 'Volume',
                capacityUnit: 'cm',
                size: 'Dimension',
                sizeUnit: 'cm'
            },
            print: {
                button: 'Imprimer',
                title: 'IMPRIMER VOTRE CONFIGURATION DE POCHE',                
                subtitle: 'Imprimez votre configuration en taille réelle',
                settings: 'Pour un résultat optimal, veillez à sélectionner le format DIN A3 et l\'option "Graphiques d\'arrière-plan" dans les paramètres d\'impression. La mise à l\'échelle doit être réglée sur 100 %.',
                page: {
                    title: 'CI Configurator',
                    code: 'Code'
                }
            },
            move: 'BOUGER',
            scale: 'ÉCHELLE',
            height: 'Height',
            volume: 'LE VOLUME',
            extend: 'Extend',
            delete: 'DÉGAGER',
            preview: 'Aperçu',
            detail: 'Detail',
            overview: 'Aperçu'
        },
        finalscreen: {
            title: 'DÉTAILS DU CONTACT',
            back: 'Retour',
            commit: 'Demande',
            total: 'TOTAL (inc. {{shipping}} shipping)',
            contact: {
                orderFor: 'Comander pour:',
                customerNumber: 'Numéro de client (facultatif)',
                firstName: 'Prénom',
                lastName: 'Nom de famille',
                companyName: "Nom de l'entreprise",
                companyContact: 'Personne de contact',
                country: 'Pays',
                zipCode: 'Code postal',
                city: 'City',
                address: 'L\'address',
                title: 'Titre',
                occupation: 'Secteur professionnel (facultatif)',
                email: 'Adresse e-mail',
                phone: 'Numéro de téléphone',
                comment: 'Commenter (facultatif)',
                titleFemale: 'Mme',
                titleMale: 'Monsieur'
            },
            orderFor: {
                'company': 'Entreprise',
                'practice': 'Cabinet',
                'private': 'Privé'
            },
            info: 'Remarque : après avoir soumis votre commande, vous recevrez d\'abord un accusé de réception contenant des informations sur la configuration de votre sac individuel. Nous vous contacterons ensuite par e-mail pour confirmer la commande et inclure les informations de paiement.',
            policy: {
                text: 'J\'ai lu les <a target="_blank" href="https://www.strauss.com/de/fr/Aspects_juridiques/CGV/Cargokonfigurator">CGV</a> du „Configurateur CI Cargo” et je les accepte.',
                accept: 'J\'accepte.'
            }
        },
        postscreen: {
            title: 'MERCI POUR VOTRE REQUÊTE',
            summary: 'Nous vérifierons votre configuration individuelle et reviendrons vers vous dès que possible.',
            questions: 'Avez-vous des questions ou des commentaires à l\'avance? Alors envoyez-nous un e-mail à ci.configurator.cargo@strauss.de',
            overview: 'Le sommaire',
            new: 'Nouvelle configuration',
            commit: 'Vers la boutique en ligne',
            total: 'TOTAL'
        },
        infoscreen: {
            title: 'COMMENT ÇA FONCTIONNE',
            subtitle: 'Tout simplement à la poche cargo individuelle pour votre produit. C\'est comme ça que ça marche :',
            text1: 'Choisissez un côté sur lequel vous souhaitez placer vos modules individuels sur la surface Velcro.',
            text2: 'Sélectionnez les modules qui correspondent exactement à vos besoins.',
            text3: 'Ajustez les modules aux dimensions souhaitées et complétez votre combinaison de sacs unique.',
            questions: 'Avez-vous des questions ou des commentaires à l\'avance?',
            phone: {
                text: 'Notre équipe de service se fera un plaisir de vous aider. Appelez-nous!',
                numberDisplay: '0 60 50 / 97 10 12',
                number: '+496050971012'
            },
            mail: {
                text: 'Ou écrivez-nous un e-mail. Nous vous répondrons dès que possible.',
                addressDisplay: 'ci.configurator.cargo@strauss.de',
                address: 'ci.configurator.cargo@strauss.de'
            }
        },
        exitscreen: {
            title: 'QUITTER L\'EDITEUR',
            text: 'Voulez-vous vraiment quitter l\'éditeur ? Vous pouvez enregistrer votre configuration actuelle avec votre code créateur ci-dessous :',
            cancel: 'ANNULER',
            quit: 'ARRÊTER'
        },
        linkfooter: {
            home: 'STRAUSS',
            impressum: 'Mentions légales',
            eula: 'CGV',
            gdpr: 'Protection des données',
            osplatform: 'OS-Platform'
        },
        logo: {
            title: 'CI CONFIGURATOR'
        },
        misc: {
            copySuffix: ' (la copie)'
        },
        errors: {
            network: 'Échec de connexion au serveur!',
            notfound: 'Ce code n\'a pas été trouvé!',
            unauthorized: 'Vous n\'êtes pas autorisé à accéder à cette ressource.'
        }
    }
};

export default translation;